<template>
  <div>
    <a-form-model
      ref="ruleForm"
      class="form-model"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="username">
        <a-input v-model="form.username" placeholder="用户名/手机号" size="large">
          <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="password">
        <a-input v-model="form.password" placeholder="密码" type="password" size="large">
          <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <div class="handle-psw d-f j-b">
          <a-checkbox :checked="checkAgree" @change="handleChange"> 记住密码 </a-checkbox>
          <!-- <a @click="goForget">忘记密码</a> -->
        </div>
        <a-button type="primary" @click="onSubmit" class="submit">登录</a-button>
      </a-form-model-item>
    </a-form-model>
    <!-- <div class="login-other">
      <div class="wx">
        <span>其他方式登录</span>
        <img @click="goWxlogin()" src="@/assets/image/login_wxicon.png" alt />
      </div>
    </div> -->
  </div>
</template>

<script>
import { httpLoginPost } from "@/axios/login.js";
export default {
  data() {
    return {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      checkAgree: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
          },
        ],
      },
    };
  },
  created() {
    // 在页面加载时从cookie获取登录信息
    let username = this.getCookie("username");
    let password = this.getCookie("password");
    // 如果存在赋值给表单，并且将记住密码勾选
    if (username) {
      this.form.username = username;
      this.form.password = password;
      this.checkAgree = true;
    }
  },
  methods: {
    //复选框状态改变
    handleChange(e) {
      this.checkAgree = e.target.checked;
    },
    //登录
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //登录请求
          httpLoginPost("/auth/oauth/token", {
            username: this.form.username, //username和password是输入框输入的值
            password: this.form.password,
            grant_type: "password", //grant_type表示授权类型，固定为"password"，必选项
          })
            .then((res) => {
              console.log(res);
              if (res.code === 200) {
                // this.$ls.set("token", res.data.access_token);
                // this.$api.getNavList().then((res) => {
                //   if (res.code === 200) {
                //     this.$ls.set(
                //       "navList",
                //       this.handleTree(res.data, "id", "pid"),
                //       60 * 60 * 1000 * 24
                //     );
                //   } else {
                //     // console.log(res);
                //     this.$message.error(res.msg);
                //   }
                // });
                //路由跳转
                this.$message.success("登录成功");
                this.$router.push("/index");
                if (this.checkAgree) {
                  this.setCookie("username", this.form.username, 7);
                  this.setCookie("password", this.form.password, 7);
                } else {
                  this.setCookie("username", "");
                  this.setCookie("password", "");
                }
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    // 获取cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },
    //扫码登录
    goWxlogin() {
      this.$router.push("/scan");
    },
    //忘记密码
    goForget() {
      this.$router.push("/forget");
    },
  },
};
</script>

<style scoped lang="less">
.form-model {
  margin-top: 40px;
  width: 100%;
  ::v-deep .ant-input-affix-wrapper {
    .ant-input {
      padding-left: 40px;
      border: none;
      border-bottom: 1px solid #e8eaee;
    }
  }
  .handle-psw {
    width: 100%;
    margin-bottom: 10px;
  }
  .submit {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
}
.login-other {
  width: 100%;
  height: 24px;
  .wx {
    height: 24px;
    span {
      color: rgba(0, 0, 0, 0.65);
      margin-right: 10px;
    }
    img {
      height: 100%;
    }
  }
}
</style>
