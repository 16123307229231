<template>
  <div>
    <a-form-model
      ref="rulePhoneForm"
      class="form-model"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="phone">
        <a-input v-model="form.phone" placeholder="手机号" size="large">
          <a-icon slot="prefix" type="mobile" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        prop="smsCode"
        :wrapper-col="{ span: 14, offset: 0 }"
        class="send-smsCode"
      >
        <a-input v-model="form.smsCode" placeholder="验证码" size="large">
          <a-icon slot="prefix" type="code" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
        <!-- 发送验证吗按钮 -->
        <div class="send-smsCode-btn">
          <a-button @click="sendSmsCode" v-if="isSend">获取验证码</a-button>
          <a-button v-if="!isSend" disabled>{{ count }}s后重新获取</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class="handle-psw d-f j-b">
          <i></i>
          <a @click="goForget">忘记密码</a>
        </div>
        <a-button type="primary" @click="onSubmit" class="submit">登录</a-button>
      </a-form-model-item>
    </a-form-model>
    <!-- <div class="login-other">
      <div class="wx">
        <span>其他方式登录</span>
        <img @click="goWxlogin()" src="@/assets/image/login_wxicon.png" alt />
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      checkAgree: false,
      form: {
        phone: "",
        smsCode: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入你的手机号!",
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "不是正确的手机格式!",
          },
        ],
        smsCode: [
          {
            required: true,
            message: "请输入验证码!",
          },
          {
            pattern: /^\d{4}$/,
            message: "验证码格式不正确!",
          },
        ],
      },
      count: 60,
      isSend: true,
      timer: null,
    };
  },
  methods: {
    //登录
    onSubmit() {
      this.$refs.rulePhoneForm.validate((valid) => {
        if (valid) {
          console.log(this.form);
          //登录请求
          // this.$api.phoneLogin(this.form).then((res) => {
          //   // console.log(res);
          //   if (res.success) {
          //     this.$ls.set("token", res.data.token);
          //     this.$api.getUserInfo().then((result) => {
          //       if (result.success) {
          //         this.$ls.set("userInfo", result.data);
          //         //路由跳转
          //         this.$message.success("登录成功");
          //         this.$router.push({ name: "main" });
          //       }
          //     });
          //   } else {
          //     this.$message.error(res.message);
          //   }
          // });
        }
      });
    },
    //发送验证码
    sendSmsCode() {
      this.$refs.rulePhoneForm.validateField("phone", (err) => {
        if (!err) {
          console.log(111);
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            //验证手机号是否注册
            // this.$api.isExist({ phone: values.mobile }).then((res) => {
            //   if (res.success) {
            //     // console.log(values); //表单的值
            //     const params = {};
            //     params.phone = values.mobile;
            //     params.smsCodeType = 3;
            //     // console.log(params);
            //     //发送短信验证码
            //     this.$api.sendSmsCode(params).then((res) => {
            //       this.$message.info(res.message);
            //     });
            //   } else {
            //     this.$message.error(res.message);
            //   }
            // });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //扫码登录
    goWxlogin() {
      this.$router.push("/scan");
    },
    //忘记密码
    goForget() {
      this.$router.push("/forget");
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.form-model {
  margin-top: 40px;
  width: 100%;
  ::v-deep .ant-input-affix-wrapper {
    .ant-input {
      padding-left: 40px;
      border: none;
      border-bottom: 1px solid #e8eaee;
    }
  }
  .send-smsCode {
    position: relative;
    .send-smsCode-btn {
      position: absolute;
      right: -140px;
      top: -10px;
    }
  }
  .handle-psw {
    width: 100%;
    margin-bottom: 10px;
  }
  .submit {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
}
.login-other {
  width: 100%;
  height: 24px;
  .wx {
    height: 24px;
    span {
      color: rgba(0, 0, 0, 0.65);
      margin-right: 10px;
    }
    img {
      height: 100%;
    }
  }
}
</style>
