<template>
  <div class="login-container">
    <div class="top d-f a-c">
      <div class="img-con">
        <img src="@/assets/image/login_logo.png" alt="" />
      </div>
    </div>
    <div class="space">
      <div class="login-box">
        <a-menu :selected-keys="[current]" mode="horizontal" @click="changeLoginWay">
          <a-menu-item key="username">账号密码登录</a-menu-item>
          <!-- <a-menu-item key="phone">手机号登录</a-menu-item> -->
        </a-menu>
        <username v-if="current === 'username'"></username>
        <phone v-if="current === 'phone'"></phone>
      </div>
    </div>
  </div>
</template>

<script>
import Username from "./username.vue";
import Phone from "./phone.vue";
export default {
  data() {
    return {
      current: "username",
    };
  },
  components: {
    Username,
    Phone,
  },
  methods: {
    //登录方式改变
    changeLoginWay(e) {
      this.current = e.key;
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.login-container {
  width: 1440px;
  height: 100vh;
  background: url("~@/assets/image/login_bac.png") no-repeat center/100% 100%;
  overflow: hidden;
  .top {
    width: 1440px;
    height: 72px;
    padding-left: 120px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.6);
    .img-con {
      width: 170px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .space {
    width: 1440px;
    height: calc(~"100% - 72px");
    position: relative;
    .login-box {
      width: 430px;
      height: 430px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(52, 38, 0, 0.1);
      opacity: 1;
      border-radius: 16px;
      position: absolute;
      right: 230px;
      top: 72px;
      padding: 60px 50px 0;
      box-sizing: border-box;
    }
    .ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
    }
  }
}
</style>
